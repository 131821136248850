import React from 'react'

import Layout from '../layouts'

const NotFoundPage = () => (
  <Layout>
    <h1>:(</h1>
    <img src="https://media.giphy.com/media/9J7tdYltWyXIY/giphy.gif" alt=":(" />
  </Layout>
)

export default NotFoundPage
